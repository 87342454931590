import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import AboutMain from "./AboutMain";
import offCanvasLogo from "../../assets/img/logo/logo-light2.png";
// Image
import Logo from "../../assets/img/logo/lite-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";
import HeaderStyleTwo from "../../components/Layout/Header/HeaderStyleTwo";

const AboutTwo = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <HeaderStyleTwo
        parentMenu="home"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        mobileNormalLogo={Logo}
        mobileStickyLogo={Logo}
        CanvasLogo={offCanvasLogo}
        headerClass="full-width-header header-style2 modify1"
        headerFullWidth="enable"
      />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="About"
        pageName="About Us"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-start */}

      {/* About Main */}
      <AboutMain />
      {/* About Main */}

      <SearchModal />
    </React.Fragment>
  );
};

export default AboutTwo;
