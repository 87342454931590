import React from "react";
import { Link } from "react-router-dom";

import topImg from "../../assets/img/banner/banner-10.png";
import bgImg from "../../assets/img/bg/home3.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
  "background-size": "cover",
  "background-position": "center",
  height: "100vh",
  display: "grid",
  "align-items": "center",
};

const routePrep = () => {
  window.location.href = "https://prep-test.com";
};

const BannerStyleTwo = () => {
  return (
    <React.Fragment>
      {/* <!-- banner section start --> */}
      <div id="rs-banner" className="rs-banner style3" style={bgStyle}>
        <div className="container pt-90 md-pt-50">
          <div className="banner-content pb-12">
            <div className="row">
              <div className="col-lg-7">
                <h1 className="banner-title white-color">
                  Building Future Zero
                </h1>
                <div className="banner-desc white-color">
                  BFZ is an technology company focused on autonomous robotics
                  and edu-tech
                </div>
                <div className="banner-desc white-color">
                  <h2 className=" white-color">Our Projects</h2>
                </div>
                <ul className="banner-btn">
                  <Link className="readon3" onClick={routePrep}>
                    Preptest
                  </Link>
                  <li>
                    <Link className="readon3 active" to="/robotics">
                      Robotics
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="banner-image hidden-md">
              <div id="stuff">
                <div data-depth="0.3">
                  <img src={topImg} alt="Top Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- banner section end --> */}
    </React.Fragment>
  );
};

export default BannerStyleTwo;
