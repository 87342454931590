import React from "react";
import SingleTeam from "../../components/Team/SingleTeam";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

// Image
import Logo from "../../assets/img/logo/lite-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

// Team Images
import teamImg1 from "../../assets/img/team/1.jpg";
import teamImg2 from "../../assets/img/team/burakfatihdindar.jpg";
import teamImg3 from "../../assets/img/team/huseyinemreuslu.jpg";
import teamImg4 from "../../assets/img/team/recepciftci.jpg";
import teamImg5 from "../../assets/img/team/5.jpg";
import teamImg6 from "../../assets/img/team/hakancoskun.jpg";
import teamImg7 from "../../assets/img/team/mustafauysal.jpg";
import teamImg8 from "../../assets/img/team/muhsinaltintop.jpg";
import HeaderStyleTwo from "../../components/Layout/Header/HeaderStyleTwo";
import offCanvasLogo from "../../assets/img/logo/logo-light2.png";
import SectionTitle from "../../components/Common/SectionTitle";

const Team = () => {
  return (
    <React.Fragment>
      <OffWrap />
      {/* <HeaderStyleTwo
        parentMenu="home"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        mobileNormalLogo={Logo}
        mobileStickyLogo={Logo}
        CanvasLogo={offCanvasLogo}
        headerClass="full-width-header header-style2 modify1"
        headerFullWidth="enable"
      /> */}

      {/* breadcrumb-area-start */}
      {/* <SiteBreadcrumb
        pageTitle="Team One"
        pageName="Team"
        breadcrumbsImg={bannerbg}
      /> */}
      {/* breadcrumb-area-End */}

      <div className="rs-team style1 blue-style pt-100 pb-50 md-pt-80 md-pb-30 white-bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-70 md-pr-14">
              <div className="about-content">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title mb-44 md-mb-0"
                  subtitleClass="sub-title orange"
                  titleClass="title mb-16"
                  title="Our Team"
                />
                {/* Section Title End */}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                itemClass="team-item"
                Image={teamImg2}
                Title="Burak Fatih Dindar"
                Designation="President & CEO"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                teamClass="team-item"
                Image={teamImg1}
                Title="Reyhan Borlu"
                Designation="Business Development Specialist"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                teamClass="team-item"
                Image={teamImg5}
                Title="Hale Yücel"
                Designation="Digital Marketer"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                teamClass="team-item"
                Image={teamImg6}
                Title="Hakan Coşkun"
                Designation="Content Creator"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                teamClass="team-item"
                Image={teamImg8}
                Title="Muhsin Altıntop"
                Designation="Developer"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                teamClass="team-item"
                Image={teamImg4}
                Title="Recep Çiftçi"
                Designation="Developer"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                teamClass="team-item"
                Image={teamImg3}
                Title="Hüseyin Emre Uslu"
                Designation="Developer"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-50">
              <SingleTeam
                teamClass="team-item"
                Image={teamImg7}
                Title="Mustafa Uysal"
                Designation="Engineer"
              />
            </div>
          </div>
        </div>
      </div>

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Team;
