import React from "react";
import { Helmet } from "react-helmet";
import OffWrap from "../../components/Layout/Header/OffWrap";
import Footer from "../../components/Layout/Footer/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import Newsletter from "../../components/Common/Newsletter";
import ContactMain from "./ContactMain";

// Image
import favIcon from "../../assets/img/fav-orange.png";
import Logo from "../../assets/img/logo/lite-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import HeaderStyleTwo from "../../components/Layout/Header/HeaderStyleTwo";
import offCanvasLogo from "../../assets/img/logo/logo-light2.png";

const ContactFour = () => {
  return (
    <React.Fragment>
      <OffWrap />
      {/* Header */}
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <HeaderStyleTwo
        parentMenu="home"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        mobileNormalLogo={Logo}
        mobileStickyLogo={Logo}
        CanvasLogo={offCanvasLogo}
        headerClass="full-width-header header-style2 modify1"
        headerFullWidth="enable"
      />
      {/* Header */}

      {/* ContactMain Section Start */}
      <ContactMain />
      {/* ContactMain Section End */}

      {/* Newsletter Section Start */}

      {/* Newsletter Section End */}

      {/* SearchModal */}
      <SearchModal />
      {/* SearchModal */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup blue-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default ContactFour;
