import React from "react";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import ContactForm from "../../components/Contact/ContactForm";
import ContactInfo from "../../components/Contact/ContactInfo";

import bannerbg from "../../assets/img/breadcrumbs/inner7.jpg";

const ContactMain = () => {
  return (
    <React.Fragment>
      {/* SiteBreadcrumb */}
      <SiteBreadcrumb
        pageTitle="Contact"
        pageName="Contact"
        breadcrumbsImg={bannerbg}
      />
      {/* SiteBreadcrumb */}

      {/* Contact Section Start */}
      <div className="rs-contact style4 pt-110 md-pt-80 pb-110 md-pb-80">
        <div className="container">
          <div className="row gutter-30">
            <div className="col-md-4 sm-mb-30">
              <ContactInfo
                boxClass=""
                title="Address"
                iconClass="fa fa-map-o pt-16"
                address="Celal Bayar University TeknoKent No:210 , YunusEmre , Manisa"
              />
            </div>
            <div className="col-md-4 sm-mb-30">
              <ContactInfo
                boxClass=""
                title="Email Addresss"
                iconClass="fa fa-envelope-open-o"
                email="info@prep-test.com"
              />
            </div>
            <div className="col-md-4">
              <ContactInfo
                boxClass=""
                title="Phone Number"
                iconClass="fa fa-headphones pt-16"
                phone="+905075576646"
              />
            </div>
          </div>

          <div className="form-part mt-60">
            <div className="row md-pl-pr">
              <div className="col-lg-6 pr-40 md-mb-30">
                <div className="map-canvas">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d34525.054461107444!2d27.350829656157146!3d38.657111475439784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b9846159b4f467%3A0xf80361ea5d300f13!2sManisa%20Celal%20Bayar%20%C3%9Cniversitesi!5e0!3m2!1str!2str!4v1666908159000!5m2!1str!2str"
                    width="600"
                    height="450"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-6 pl-0">
                <div className="contact-widget event-bg">
                  <SectionTitle
                    sectionClass="sec-title mb-50 xs-mb-30"
                    titleClass="title black-color mb-14"
                    title="Get In Touch"
                    descClass="desc big"
                  />

                  {/* Contact Form */}
                  <ContactForm submitBtnClass="btn-send " btnText="Submit" />
                  {/* Contact Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;
