import React from "react";

import SectionTitle from "../../components/Common/SectionTitle";

// About Image
import AboutImage from "../../assets/img/about/about2orange.png";

const AboutText = (props) => {
  return (
    <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
            <div className="img-part">
              <img className="about-main" src={AboutImage} alt="About Image" />
            </div>
          </div>
          <div className="col-lg-6 pr-70 md-pr-14">
            <div className="about-content">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title mb-44 md-mb-0"
                subtitleClass="sub-title orange"
                subtitle="About BFZ"
                titleClass="title mb-16"
                title="Welcome to BFZ Company"
                descClass="bold-text mb-22"
                description="BFZ Mekatronik Software Engineering Company is established, with support of
                Tubitak 1512, in Manisa Teknokent to work on new generation technologies.
                The firm focus on robotics and educational technologies."
                secondDescClass="desc"
                secondDescription="In the robotics Project studies are carried out on fast order picking for the
                grocery industry with autonomous mobile robots and intellegent software. The
                Project which started on November 2022, is supported by Tubitak 1512.
                Preptest targets students who are prepearing for international university
                exams especially Tolc, Boccouni, Till for Italy Universities and SAT for
                American Universities."
              />
              {/* Section Title End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutText;
