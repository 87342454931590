import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItems = (props) => {
  const { parentMenu, secondParentMenu } = props;

  const location = useLocation();

  return (
    <React.Fragment>
      <li
        className={
          parentMenu === "home"
            ? "rs-mega-menu menu-item-has-children current-menu-item"
            : "rs-mega-menu menu-item-has-children"
        }
      >
        <Link to="/">Home</Link>
      </li>
      <li
        className={
          parentMenu === "about"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="/about">About</Link>
      </li>

      {/* <li
        className={
          parentMenu === "pages"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="/team">Team</Link>
      </li> */}
      {/* <li
        className={
          parentMenu === "blog"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Blog</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/blog"
              className={location.pathname === "/blog" ? "active-menu" : ""}
            >
              Blog
            </Link>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "blogSidebar"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Blog Sidebar
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/blog/blog-left-sidebar"
                  className={
                    location.pathname === "/blog/blog-left-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Blog Left Sidebar
                </Link>
              </li>
              <li>
                <Link
                  to="/blog/blog-right-sidebar"
                  className={
                    location.pathname === "/blog/blog-right-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Blog Right Sidebar
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to="#"
              className={
                secondParentMenu === "blogSingle"
                  ? "menu-item-has-children active-menu"
                  : "menu-item-has-children"
              }
            >
              Single Post
            </Link>
            <ul className="sub-menu">
              <li>
                <Link
                  to="/blog/single-post-left-sidebar"
                  className={
                    location.pathname === "/blog/single-post-left-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Post Left Sidebar
                </Link>
              </li>
              <li>
                <Link
                  to="/blog/single-post-right-sidebar"
                  className={
                    location.pathname === "/blog/single-post-right-sidebar"
                      ? "active-menu"
                      : ""
                  }
                >
                  Post Right Sidebar
                </Link>
              </li>
              <li>
                <Link
                  to="/blog/single-post-full-width"
                  className={
                    location.pathname === "/blog/single-post-full-width"
                      ? "active-menu"
                      : ""
                  }
                >
                  Full Width Post
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </li> */}
      <li
        className={
          parentMenu === "contact"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="/contact">Contact</Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
