import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from "react-router-dom";

//Custom Components

import HomeFour from "../pages/home-4";

import AboutTwo from "../pages/about-2";

import Team from "../pages/team";

import ContactFour from "../pages/contact-4";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={HomeFour} />
          <Route path="/about" component={AboutTwo} />
          <Route path="/team" exact component={Team} />
          {/* <Route path="/team-2" component={TeamTwo} />
          <Route path="/team/team-single" component={TeamSingle} /> */}
          {/* <Route path="/event" component={Event} />
          <Route path="/event-2" component={EventTwo} />
          <Route path="/event-3" component={EventThree} /> */}
          {/* <Route path="/gallery" component={Gallery} />
          <Route path="/gallery-2" component={GalleryTwo} />
          <Route path="/gallery-3" component={GalleryThree} /> */}
          {/* <Route path="/faq" component={Faq} /> */}
          {/* <Route path="/blog" exact component={Blog} />
          <Route path="/blog/blog-left-sidebar" component={BlogLeft} />
          <Route path="/blog/blog-right-sidebar" component={BlogRight} />
          <Route
            path="/blog/single-post-left-sidebar"
            component={SinglePostLeftSidebar}
          />
          <Route
            path="/blog/single-post-right-sidebar"
            component={SinglePostRightSidebar}
          />
          <Route
            path="/blog/single-post-full-width"
            component={SinglePostFullWidth}
          /> */}
          <Route path="/contact" component={ContactFour} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
